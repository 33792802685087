import "../styles/pages/participation.scss";

import { useState } from "react";
import { useSelector } from "react-redux";
import { content } from "../assets/text.js";
import participation from "../assets/img/participation/participation.jpg";

import {
    formValidation,
    formSend,
    cleanForm,
    cleanYesPart,
} from "../libs/form.js";
import Modal from "../components/Modal.jsx";

export default function Participation() {
    const lang = useSelector((state) => state.lang);
    const textValues = content[lang];
    const [showForm, setShowForm] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [textModal, setTextModal] = useState(textValues.participation.answer);

    const handleChange = (event) => {
        setShowForm(event.target.value === "yes" ? true : false);
        if (event.target.value === "no") {
            cleanYesPart(event.target.form);
        }
    };

    const handleSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        let validation = formValidation(event.target);

        if (validation === true) {
            formSend(event.target)
                .then((success) => {
                    if (!success) {
                        setTextModal(textValues.participation.error);
                    } else {
                        setTextModal(textValues.participation.answer);
                        cleanForm(event.target);
                        setShowForm(false);
                    }
                })
                .catch((error) => {
                    console.log("Erreur envoi form : ", error);
                    setTextModal(textValues.participation.error);
                });
            setModalOpen(true);
        }
    };

    return (
        <main id="pageParticipation">
            <h1>{textValues.participation.title}</h1>
            <p
                dangerouslySetInnerHTML={{
                    __html: textValues.participation.text,
                }}
            ></p>
            <p
                class="indic"
                dangerouslySetInnerHTML={{
                    __html: textValues.participation.contact,
                }}
            ></p>
            <div id="participationForm">
                <form
                    action=""
                    method="post"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <label htmlFor="name">
                        {textValues.participation.label1}
                    </label>
                    <input type="text" id="name" name="name" /*required*/ />

                    {/* Come or not */}
                    <div className="formYesNo">
                        <input
                            type="radio"
                            id="yes"
                            name="participation"
                            value="yes"
                            checked={showForm === true}
                            onChange={handleChange}
                        />
                        <label htmlFor="yes">
                            {textValues.participation.label8}
                        </label>

                        <input
                            type="radio"
                            id="no"
                            name="participation"
                            value="no"
                            checked={showForm === false}
                            onChange={handleChange}
                        />
                        <label htmlFor="no">
                            {textValues.participation.label9}
                        </label>
                    </div>

                    {/* Rest of the form if the person comes */}
                    {showForm && (
                        <>
                            <label htmlFor="number">
                                {textValues.participation.label2}
                            </label>
                            <input
                                type="number"
                                id="number"
                                name="number"
                                min="1"
                                max="10"
                                // required
                            />

                            <label htmlFor="phone">
                                {textValues.participation.label3}
                            </label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                // required
                            />

                            <label htmlFor="food">
                                {textValues.participation.label4}
                            </label>
                            <input type="text" id="food" name="food" />

                            <label htmlFor="song">
                                {textValues.participation.label5}
                            </label>
                            <input type="text" id="song" name="song" />

                            <label htmlFor="comment">
                                {textValues.participation.label6}
                            </label>
                            <textarea id="comment" name="comment" />
                        </>
                    )}

                    <button type="submit">
                        {textValues.participation.label7}
                    </button>
                </form>
                <img src={participation} alt="Participation" />
                <Modal onClose={() => setModalOpen(false)} show={modalOpen}>
                    <h2
                        dangerouslySetInnerHTML={{
                            __html: textModal,
                        }}
                    ></h2>
                </Modal>
            </div>
        </main>
    );
}

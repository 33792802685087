// Infos on the ceremony
import "../styles/pages/wedding.scss";

import { useSelector } from "react-redux";
import { content } from "../assets/text.js";
import LeafletMap from "../components/LeafletMap";

import imgPriseEnCharge from "../assets/img/weeding/Ceremonie_1.jpg";
import imgDressCode from "../assets/img/weeding/Ceremonie_2.jpg";
import mapHotel from "../assets/img/weeding/mapaCoconucoBeach.png";

export default function Wedding() {
    const lang = useSelector((state) => state.lang);
    const textValues = content[lang];

    const position = [11.2533, -73.5479];
    const popup = (
        <a
            href="https://maps.app.goo.gl/WgdmGNrMh6dJWLfd7"
            target="_blank"
            rel="noreferrer"
        >
            {textValues.ceremonie.textLieu}
        </a>
    );

    return (
        <main id="pageWeeding">
            <h1>{textValues.ceremonie.title}</h1>

            {lang === "ES" ? (
                <div>
                    <div className="weedingDate">
                        <p>{textValues.ceremonie.textDate}</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: textValues.ceremonie.textAddress,
                            }}
                        ></p>
                        <p style={{ margin: "auto" }}>
                            {textValues.ceremonie.text}
                        </p>
                    </div>
                    <img src={mapHotel} alt="Hotel Coconuco Beach Resort" />
                </div>
            ) : (
                <div className="weedingDate">
                    <p>{textValues.ceremonie.textDate}</p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: textValues.ceremonie.textAddress,
                        }}
                    ></p>
                    <p>{textValues.ceremonie.text}</p>
                </div>
            )}

            <LeafletMap position={position} popup={popup} />

            <div>
                <p
                    dangerouslySetInnerHTML={{
                        __html: textValues.ceremonie.textHotel,
                    }}
                ></p>
                <img src={imgPriseEnCharge} alt="" />
            </div>

            {textValues.ceremonie.textDressCode ? (
                <div id="divDressCode">
                    <img src={imgDressCode} alt="" />
                    <p
                        dangerouslySetInnerHTML={{
                            __html: textValues.ceremonie.textDressCode,
                        }}
                    ></p>
                </div>
            ) : (
                <img src={imgDressCode} alt="" id="imageDressCode" />
            )}
        </main>
    );
}

import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { change } from "../redux/store.js";
import { content } from "../assets/text.js";

export default function SelectLang() {
    let languages = [];
    for (let property in content) {
        languages.push(property);
    }

    const filterRef = useRef(0);
    const [state, setState] = useState(false); // false = closed, true = open

    let selectedLanguage = useSelector((state) => state.lang);
    const dispatch = useDispatch();

    const handleOutsideClick = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setState(false);
        }
    };

    const selectOption = (choice) => {
        dispatch(change(choice));
        setState(false); // Ferme le menu après sélection
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () =>
            document.removeEventListener("mousedown", handleOutsideClick);
    }, []);

    return (
        <div
            id="langFilter"
            ref={filterRef}
            style={{ position: "relative", display: "inline-block" }}
        >
            <button
                onClick={() => setState(!state)}
                style={{ padding: "10px", margin: "5px", borderRadius: "5px" }}
            >
                {selectedLanguage || "Choose Language"} {state ? "▲" : "▼"}
            </button>
            {state && (
                <div
                    style={{
                        position: "absolute",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                        padding: "10px",
                        backgroundColor: "#f9f9f9",
                    }}
                >
                    {languages.map(
                        (choice) =>
                            choice !== selectedLanguage && (
                                <div
                                    key={choice}
                                    onClick={() => selectOption(choice)}
                                    style={{
                                        padding: "5px",
                                        cursor: "pointer",
                                    }}
                                >
                                    {choice}
                                </div>
                            )
                    )}
                </div>
            )}
        </div>
    );
}

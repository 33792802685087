import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { change } from "./redux/store.js";
import { content } from "./assets/text.js";

import Header from "./components/Header";
import Home from "./pages/Home";
import Wedding from "./pages/Wedding";
import Infos from "./pages/Infos";
import Participation from "./pages/Participation";
import Activities from "./pages/Activities";
import Connexion from "./pages/Connexion";

const checkLang = (textArray, initLang) => {
    let resultLang = '';
    let result = false;

    if(initLang.includes('-')) {
        resultLang = initLang.split("-")[0].toUpperCase()
    } else {
        resultLang = initLang.toUpperCase();
    }

    if(Object.keys(textArray).includes(resultLang)) {
        result = true;
    }
    
    return [resultLang, result];
};

export default function App() {
    const access = true; // useSelector((state) => state.access);
    const dispatch = useDispatch();

    let lang = useSelector((state) => state.lang);

    if(lang === null) {
        let [firstLang, resultFirstLang] = checkLang(content, navigator.language);

        if(resultFirstLang === true) {
            lang = firstLang;
        } else {
            navigator.languages.every(iterationLang => {
                let [nLang, resultNLang] = checkLang(content, iterationLang)
                if(resultNLang === true) {
                    lang = nLang;
                }
                return !resultNLang;
            });
        }

        if(lang === null) {
            lang = 'FR';
        }
        dispatch(change(lang));
    } else if(!Object.keys(content).includes(lang)) {
        lang = 'FR';
        dispatch(change(lang));
    }

    if (access) {
        return (
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="wedding" element={<Wedding />} />
                    <Route path="infos" element={<Infos />} />
                    <Route path="rsvp" element={<Participation />} />
                    <Route path="activities" element={<Activities />} />
                    <Route path="*" element={<h1>Error 404 - Not Found</h1>} />
                </Routes>
            </Router>
        );
    } else {
        return (
            <Router>
                <Routes>
                    <Route path="*" element={<Connexion />} />
                </Routes>
            </Router>
        );
    }
}

// Presentation of the site and the purpose of the site
import "../styles/pages/home.scss";
import pictureUs from "../assets/img/home/us.jpg";

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { content } from "../assets/text.js";

export default function Home() {
    const lang = useSelector((state) => state.lang);
    const textValues = content[lang];

    return (
        <main id="pageHome">
            <div className="homeImg">
                <img src={pictureUs} alt="Léa & Jorge" />
                <div id="pageHomeRSVP">
                    <Link to="/rsvp">RSVP</Link>
                </div>
            </div>
            <div id="homeText">
                <h1>{textValues.home.title}</h1>
                <p id="homeDate">
                    {textValues.home.dateTitle}
                    <time dateTime="2025.02.15">{textValues.home.date}</time>
                </p>
                <p
                    dangerouslySetInnerHTML={{ __html: textValues.home.text }}
                ></p>
            </div>
        </main>
    );
}

import "../styles/components/header.scss";

import { content } from "../assets/text.js";
import { Link } from "react-router-dom";
import SelectLang from "./SelectLang.jsx";
import { useSelector } from "react-redux";

export default function Header() {
    const lang = useSelector((state) => state.lang);
    const textValues = content[lang];

    return (
        <header>
            <div id="banderole">
                <Link to="/" alt="retour à l'accueil">
                    <span id="title">Léa & Jorge</span>
                </Link>
            </div>
            <SelectLang />
            <nav>
                <ul>
                    <li>
                        <Link to="/" alt="Aller à l'accuei">
                            {textValues.nav.home ?? "Accueil"}
                        </Link>
                    </li>
                    <li>
                        <Link to="/wedding" alt="Infos sur la cérémonie">
                            {textValues.nav.ceremonie ?? "Cérémonie"}
                        </Link>
                    </li>

                    {lang !== "ES" && (
                        <li>
                            <Link to="/infos" alt="Infos pratiques">
                                {textValues.nav.infos ?? "Infos Pratiques"}
                            </Link>
                        </li>
                    )}

                    <li>
                        <Link to="/rsvp" alt="S'inscrire">
                            {textValues.nav.participation ?? "Participation"}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/activities"
                            alt="Voir les activités possibles"
                        >
                            {textValues.nav.activities ?? "Activités possibles"}
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

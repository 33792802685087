const checkNameErrorShort = "The name must be at least 2 characters long.";
const checkNameErrorSpecialChar = "No special characters allowed in the name.";
const checkPhoneError = "Enter a valid phone number";
const checkNumberErrorNoNumeric = "Number waiting.";
const checkNumberErrorOutLimits_1 = "Choose a number between ";
const checkNumberErrorOutLimits_2 = " and ";

/** function colorValidInput
 * Change the background color and the border of an input if
 * it's valid or not
 * @param {Element valid or invalid} element 
 * @param {Status of the input, true for valid, else false} validate 
 */
function colorValidInput(element, validate) {
    let style_error = 'input_error';
    let style_valid = 'input_valid';

    if (validate && element.classList.contains(style_error)) {
        element.classList.remove(style_error);
        element.classList.add(style_valid);
    }
    else if (!validate && !element.classList.contains(style_error)) {
        element.classList.add(style_error);
        if (element.classList.contains(style_valid)) {
            element.classList.remove(style_valid);
        }
    }
}

/** cleanStyleInput
 * Clean the style of an input
 * @param {Element to clean style} element 
 */
function cleanInput(element) {
    if (element.classList.contains('input_valid')) {
        element.classList.remove('input_valid');
    }
    if (element.classList.contains('input_error')) {
        element.classList.remove('input_error');
    }

    if (element.oninput) { element.oninput = null; }
    hideErrorMessage(element);
}

/** printErrorMessage
 * Print an error message under the not validate input
 * @param {Element not validate} element 
 * @param {Text to print} texte 
 */
function printErrorMessage(element, texte) {
    let nextSibling = element.nextSibling;

    if (nextSibling.className === 'error_message') {
        nextSibling.style.display = 'block';
        nextSibling.innerText = texte;
    } else {
        let pError = document.createElement('p');
        pError.innerText = texte;
        pError.classList.add('error_message');
        element.insertAdjacentElement('afterend', pError);
    }
}

/** hideErrorMessage
 * Hide the Error message under an input
 * @param {Element valid under which the text must be hide} element 
 */
function hideErrorMessage(element) {
    const nextSibling = element.nextSibling;
    let errorMessage = nextSibling.className === 'error_message' ? true : false;

    if (errorMessage) {
        nextSibling.style.display = 'none';
    }
}

/** checkName
 * Check a string with a regex
 * @param {string input} element 
 * @param {regex to check string} regex 
 * @param {true to check if the string is empty} checkEmpty 
 * @returns true if the string is correct, else false
 */
function checkName(element) {
    let retValue = true;
    let regex = /^[a-zA-Z -]+$/;
    let inputText = element.value;

    if (inputText.trim() === "" || !inputText || inputText.length < 2) {
        retValue = false; // Sentence empty
        colorValidInput(element, false);
        printErrorMessage(element, checkNameErrorShort);
    } else if (!regex.test(inputText)) {
        retValue = false; // Error in sentence
        colorValidInput(element, false);
        printErrorMessage(element, checkNameErrorSpecialChar);
    } else {
        retValue = true; // No error in sentence
        colorValidInput(element, true);
        hideErrorMessage(element);
    }

    return retValue;
}

/** checkEmail
 * Check if the email is correct
 * @param {Element to check} element 
 * @returns true if element is valid, else false
 */
// function checkEmail(element) {
//     let retValue = true;
//     let regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
//     let inputText = element.value;

//     if(!regex.test(inputText)) {
//             colorValidInput(element, false);
//             printErrorMessage(element, "Votre adresse mail doit être valide");
//             retValue = false; // Error in sentence
//     } else {
//         colorValidInput(element, true);
//         hideErrorMessage(element);
//         retValue = true; // No error in sentence
//     }

//     return retValue;
// }

/**
 * 
 * @param {*} element 
 * @returns 
 */
function checkPhone(element) {
    let retValue = true;
    let regex = /^\+?\d{1,3}[\s.-]?\(?\d{1,3}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,4}$/;
    let inputText = element.value;

    if (!regex.test(inputText)) {
        colorValidInput(element, false);
        printErrorMessage(element, checkPhoneError);
        retValue = false; // Error in sentence
    } else {
        colorValidInput(element, true);
        hideErrorMessage(element);
        retValue = true; // No error in sentence
    }

    return retValue;

}

/** checkDate
 * Check if the date is valid, between 2 defined age
 * Check also if the person give a past date or not
 * @param {Date element to check} element 
 * @param {Min age to have} min 
 * @param {Max age to have} max 
 * @returns true if element is valid, else false
 */
// function checkDate(element, min, max) {
//     let retValue = true;

//     if(!element.value || element.value === '') {
//         colorValidInput(element, false);
//         printErrorMessage(element, "Vous devez entrer votre date de naissance.");
//         retValue = false;
//     } else {
//         let dateYear = Number(element.value.split('-')[0]);
//         let currentYear = new Date().getFullYear();

//         // Trop jeune ou trop vieux
//         // Permet aussi d'éviter les gens qui mettent une date pas encore arrivée
//         if((currentYear - dateYear) < 0) {
//             colorValidInput(element, false);
//             printErrorMessage(element, "Vous n'êtes pas encore né ?!");
//             retValue = false;
//         } else if((currentYear - dateYear) < min) {
//             colorValidInput(element, false);
//             printErrorMessage(element, "Vous devez avoir au moins "+ min +"ans.");
//             retValue = false;
//         } else if ((currentYear - dateYear) > max) {
//             colorValidInput(element, false);
//             printErrorMessage(element, "Vous semblez un peu vieux.");
//             retValue = false;
//         } else {
//             colorValidInput(element, true);
//             hideErrorMessage(element);
//             retValue = true;
//         }
//     }

//     return retValue;
// }

/** checkNumber
 * Check if the number is valid, not null
 * The event listener check if the value is between the defined limits
 * @param {Number element to check} element 
 * @returns true if element is valid, else false
 */
function checkNumber(element) {
    let retValue = true;

    if (element.value === "") {
        colorValidInput(element, false);
        printErrorMessage(element, checkNumberErrorNoNumeric);
        retValue = false;
    } else if (element.min !== "" && element.max !== "") {
        let min = parseInt(element.min);
        let max = parseInt(element.max);
        let value = parseInt(element.value);
        if (value < min || value > max) {
            colorValidInput(element, false);
            printErrorMessage(element, checkNumberErrorOutLimits_1 + element.min + checkNumberErrorOutLimits_2 + element.max + ".");
            retValue = false;
        } else {
            colorValidInput(element, true);
            hideErrorMessage(element);
            retValue = true;
        }
    } else {
        colorValidInput(element, true);
        hideErrorMessage(element);
        retValue = true;
    }
    return retValue;
}

/** checkSelectionList
 * Check if there is at least one checkbox checked
 * @param {Div with checkbox inside} divInput 
 * @returns true if element is valid, else false
 */
// function checkSelectionList(divInput) {
//     let retValue = true;

//     let array = Array.from(divInput.getElementsByTagName('input'));
//     if(array.every(elem => {
//         return !elem.checked;
//     })) {
//         colorValidInput(divInput, false);
//         printErrorMessage(divInput, "Vous devez choisir une option.");
//         retValue = false;
//     }
//     else {
//         colorValidInput(divInput, true);
//         hideErrorMessage(divInput);
//         retValue = true;
//     }
//     // Adapt the style of the div
//     divInput.style.borderRadius = "8px";

//     return retValue;
// }

/**
 * 
 * @param {*} form 
 * @returns 
 */
export function formValidation(form) {
    let formValid = true;
    formValid &&= checkName(form.querySelector('#name'));

    if (form.querySelector('#yes').checked) {
        if(form.querySelector('#number') !== null && form.querySelector('#phone') !== null) {
            formValid &&= checkNumber(form.querySelector('#number'));
            formValid &&= checkPhone(form.querySelector('#phone'));
        } else {
            formValid = false;
        }
        return formValid;
    } else {
        return formValid;
    }
};

/**
 * 
 * @param {*} data
 * @returns 
 */
export function formSend(form) {
    let dataForm = {
        name: form.querySelector('#name').value,
        coming: form.querySelector('#yes').checked,
    };
    if (dataForm.coming) {
        dataForm.number = form.querySelector('#number').value;
        dataForm.phone = form.querySelector('#phone').value;
        dataForm.food = form.querySelector('#food').value;
        dataForm.song = form.querySelector('#song').value;
        dataForm.comment = form.querySelector('#comment').value;
    }
    const url_prod = 'https://leita-jorgito.com/api/formReceive';

    return fetch(url_prod, {
        method: 'POST', // Méthode de la requête
        headers: {
            'Content-Type': 'application/json', // Définit le type de contenu de la requête
        },
        body: JSON.stringify({ message: JSON.stringify(dataForm) }), // Corps de la requête
    })
        .then((response) => {
            if (!response.ok) { // Vérifie si le statut de la réponse est 200-299
                throw new Error('Network response was not ok');
            }
            return response.json(); // Retourne une promesse résolue avec le résultat de `response.json()`
        })
        .then(data => {
            if (data.success === true)
                return true;
            else
                return false;
        })
        .catch((error) => {
            console.error('Error on sending', error);
            throw error; // Gère les erreurs de réseau ou de parsing
        });
};

/**
 * 
 * @param {*} form 
 */
export function cleanForm(form) {
    cleanInput(form.querySelector('#name'));
    form.reset();
}

export function cleanYesPart(form) {
    cleanInput(form.querySelector('#number'));
    cleanInput(form.querySelector('#phone'));
}
import "../styles/pages/infos.scss";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { content } from "../assets/text.js";

import imgHotelHilton from "../assets/img/infos/Hotel Hilton.jpg";
import imgMonserrate from "../assets/img/infos/monserrate-bogota.jpg";
import imgPlazaBolivar from "../assets/img/infos/plaza-de-bolivar-bogota.jpg";
import imgFormalite from "../assets/img/infos/formalite.jpeg";
import imgVol from "../assets/img/infos/ross-parmly-rf6ywHVkrlY-unsplash.jpg";
import mapHotel from "../assets/img/weeding/mapaCoconucoBeach.png";

export default function Infos() {
    const navigate = useNavigate();
    const lang = useSelector((state) => state.lang);
    const textValues = content[lang];

    if (lang === "ES") {
        navigate("/");
    }

    return (
        <main id="pageInfos">
            <h1>{textValues.infos.title}</h1>
            <h2>{textValues.infos.subtitle1}</h2>
            <div className="groupInfos infosFormalite">
                <img src={imgFormalite} alt="Formalités" />
                <div className="infosText">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: textValues.infos.textForm,
                        }}
                    ></p>
                </div>
            </div>
            <div className="groupInfos infosHotel">
                <div className="infosText">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: textValues.infos.textHotel,
                        }}
                    ></p>
                </div>
                <img src={imgHotelHilton} alt="Hotel Hilton" />
            </div>
            <div className="groupInfos infosVisit">
                <img src={imgPlazaBolivar} alt="Visits Bogota" />
                <img src={imgMonserrate} alt="Monserrate" />
                <div className="infosText">
                    <p
                        dangerouslySetInnerHTML={{
                            __html: textValues.infos.textVisit,
                        }}
                    ></p>
                </div>
            </div>
            <div className="groupInfos infosVol">
                <div className="infosText">
                    <div className="infosText">
                        <h2>{textValues.infos.subtitle2}</h2>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: textValues.infos.textVol,
                            }}
                        ></p>
                    </div>
                </div>
                <img src={imgVol} alt="Vol et accès au mariage" />
            </div>
            <div className="groupInfos infosTransfert">
                <img src={mapHotel} alt="Monserrate" />
                <div className="infosText">
                    <h2>{textValues.infos.subtitle3}</h2>
                    <p style={{ textAlign: "center" }}>
                        {textValues.infos.textTransfert_title1}
                    </p>
                    <p>{textValues.infos.textTransfert_text1}</p>
                    <p style={{ textAlign: "center" }}>
                        {textValues.infos.textTransfert_title2}
                    </p>
                    <p>{textValues.infos.textTransfert_text2}</p>
                </div>
            </div>

            <h2>{textValues.infos.subtitle4}</h2>
            <ul
                dangerouslySetInnerHTML={{ __html: textValues.infos.text4 }}
            ></ul>
        </main>
    );
}

import { configureStore, createSlice } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const manageStore = createSlice({
  name: 'store',
  initialState: {
    lang: null, //navigator.language.split("-")[0].toUpperCase(),
    access: false,
  },
  reducers: {
    change: (state, action) => {
      state.lang = action.payload;
      return state;
    },
    resetLang: (state) => {
      state.lang = null;
      return state;
    },
    connect: (state) => {
      state.access = true;
      return state;
    },
  },
})

const persistedReducer = persistReducer(persistConfig, manageStore.reducer);

// Action creators are generated for each case reducer function
export const { change, resetLang, connect } = manageStore.actions;

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export const textES = {
    nav: {
        home: 'Bienvenidos',
        ceremonie: 'Boda',
        infos: 'Test',
        participation: 'Asistencia',
        activities: 'Actividades',
    },
    home: {
        title: 'Nuestra boda',
        dateTitle: 'Fecha',
        date: `15 de febrero del 2025`,
        text: `Querida familia y amigos,
                <br /><br />
                ¡Nos llena de felicidad, emoción y de muchísima ilusión compartir con ustedes nuestro 
                matrimonio! Esperamos que ese día sea igualmente feliz e inolvidable para cada uno 
                de ustedes, quienes ocupan un lugar muy especial en nuestros corazones.
                <br/><br />
                Para nosotros resulta maravilloso organizar un día en el que dos culturas se unen para 
                celebrar el amor, bailar, dar gracias y compartir la vida.
                <br /><br/>
                Aquí encontraran algunos detalles, información sobre el hotel, actividades y destinos turísticos.
                <br /><br/>
                Por favor no olvides confirmar tu asistencia.
                <br /><br/>
                ¡Gracias y esperamos verlos muy pronto!`
    },
    ceremonie: {
        title: 'Ceremonia & recepción',
        textDate: `Fecha: 15.02.2025 a las 4pm`,
        textAddress: `Locación: <a href="https://maps.app.goo.gl/WgdmGNrMh6dJWLfd7" target="_blank">Hotel Coconuco Beach Resort
                    <br />
                    Km 1 via Palomino, Dibulla
                    <br />
                    La Guajira, Colombia</a>`,
        text: `Empezamos el viernes 14 de febrero del 2025 con una linda noche en el 
                hotel Coconuco Beach Resort.`,
        textLieu: `Enlace Google Maps`,
        textHotel: `Las 2 noches del 14 y 15 de febrero 2025 están incluidas (igualmente está incluido el desayuno).`,
        textDressCode: ``,
    },
    infos: {
        title: ``,
        subtitle1: ``,
        text11: ``,
        text12: ``,
        text13: ``,
        subtitle2: ``,
        text2: ``,
        subtitle3: ``,
        text3: ``,
        subtitle4: ``,
        text4: ``,
    },
    participation: {
        title: `Confirmación de asistencia`,
        text: `Hola, nos gustaría confirmar si vendrás a nuestro matrimonio. 
                Por favor confirma tu asistencia en el siguiente formulario 
                <br />
                <span id="participationLimitDate">antes del 15 de noviembre del 2024</span>.`,
        contact: `Si tiene problemas durante el registro, por favor envíe un correo electrónico a 
                <a href="mailto:contact@leita-jorgito.com">
                    contact@leita-jorgito.com
                </a>
                o contacte a Léa`,
        label1: `Nombre y apellido`,
        label2: 'Numéro de personas',
        label3: `Celular`,
        label4: `¿Restricciones alimenticias? ¿Alergias? ¿Cuales?`,
        label5: `Compartenos dos canciones que quisieras escuchar/bailar en nuestra boda`,
        label6: `Comentarios`,
        label7: `Enviar`,
        label8: `Si`,
        label9: `No`,
        answer: 'Respuesta enviada !',
        error: "Lo sentimos, se ha producido un error.<br/>Por favor, intente registrarse de nuevo."
    },
    activities: {
        title: `Actividades`,
        subtitle1: `Actividades cerca del hotel en Palomino`,
        activity1: 'Tubing',
        activity2: 'Surf',
        activity3: 'Yoga',
        activity4: 'Relajarse',
        subtitle2: `Excursiones en la region de Santa Marta`,
        visit1: `<p>Parque nacional de Tayrona<br /><i>cerrado del 1 al 15 de febrero (caminatas y playas)</i></p>`,
        visit2: `<p>Minca<br /><i>(caminatas, cascadas y observación de aves)</i></p>`,
        visit3: `<p>Centro Histórico de Santa Marta</p>`,
        visit4: `<p>Carnaval de Barranquilla del 1 al 4 de marzo del 2025</p>`,
        text: ``,
        subtitle3: `Si quisieras quedarse en Palomino después de la boda, acá te damos más alojamientos en Palomino`,
        hotel1: `<p><a href="https://www.makaopalomino.com/?lang=en" alt="Makao Beach Hotel website">Makao Beach Hotel</a></p>`,
        hotel2: `<p><a href="https://www.hotelchiniu.com/" alt="Chiniü Hotel website">Hotel Chiniü</a></p>`,
        hotel3: `<p><a href="https://www.aite.com.co/" alt="Aite Eco Resort website">Aite Eco Resort</a></p>`,
        hotel4: `<p><a href="https://www.thedreamer.com/" alt="Dreamer Palomino Hostal website">Dreamer Palomino Hostal</a></p>`,
    }
}
export const textDE = {
    nav: {
        home: "Willkommen",
        ceremonie: "Hochzeit",
        infos: "Praktische Informationen",
        participation: "RSVP",
        activities: "Aktivitäten",
    },
    home: {
        title: "Unsere Hochzeit",
        dateTitle: "Datum",
        date: `15. Februar 2025`,
        text: `Liebe Familie und Freunde,
                <br />
                <br />
                wir sind überglücklich, aufgeregt und freuen uns sehr, 
                unseren Hochzeitstag mit Euch zu verbringen! Wir hoffen, 
                dass dieser Tag für jeden von Euch ebenso glücklich und unvergesslich sein wird.
                <br />
                <br />
                Es ist wunderbar für uns, einen Tag zu organisieren, an dem zwei Kulturen 
                zusammenkommen, um die Liebe zu feiern, zu tanzen, zu danken und unvergessliche 
                Momente zu teilen.
                <br />
                <br />
                Hier findest Du einige Details über unsere Hochzeit – Informationen, Aktivitäten vor 
                und nach der Hochzeit, Reiseziele, Flug- und Transportinformationen.
                <br />
                <br />
                Bitte vergiss nicht, Dich auf der RSVP-Seite anzumelden.
                <br />
                <br />
                Danke und wir hoffen, Dich bald zu sehen!`,
    },
    ceremonie: {
        title: "Trauung und Feier",
        textDate: `Datum: 15.02.2025 um 16 Uhr`,
        textAddress: `Ort: <a href="https://maps.app.goo.gl/WgdmGNrMh6dJWLfd7" target="_blank">Hotel Coconuco Beach Resort
                    <br />
                    Km 1 via Palomino, Dibulla
                    <br />
                    La Guajira, Kolumbien</a>`,
        text: `Wir treffen uns am Freitag, den 14. Februar 2025 im Hotel Coconuco Beach Resort, um einen gemütlichen Abend zusammen zu verbringen.`,
        textLieu: `Link Google Maps`,
        textHotel: `Wir werden die Kosten für die 2 Hotelübernachtungen vom 14. bis 16. Februar 2025 
                    (Frühstück mit inbegriffen) übernehmen.`,
        textDressCode: `Es gibt keinen Dresscode, aber die Zeremonie findet am Strand statt und es ist Sommer, 
                    also plant legere Kleidung ein (<i>leichte Kleider und Hosen, Baumwoll-/Leinenhemden</i>).`,
    },
    infos: {
        title: `Praktische Informationen`,
        subtitle1: ``,
        textForm: `Für deutsche Staatsangehörige ist ein gültiger Reisepass erforderlich.
                <br />
                Es gibt keine Impfpflicht.
                <br />
                Für weitere Infos könnt ihr gerne einen Blick auf&nbsp;
                <a
                    href="https://www.auswaertiges-amt.de/de/service/laender/kolumbien-node/kolumbiensicherheit/201516"
                    target="_blank"
                    rel="noreferrer"
                >
                    die Website der Regierung
                </a>
                &nbsp;werfen.
                <br /><br/>
                Das Formular 
                <a
                    href="https://apps.migracioncolombia.gov.co/pre-registro/en"
                    target="_blank"
                    rel="noreferrer"
                >
                    « Check mig »
                </a>
                muss ab 72 Stunden vor der Einreise bis zu 1 Stunde davor ausgefüllt werden.`,
        textHotel: `Ankunftsflughafen in Kolumbien: Bogotá
                <br />
                Wir empfehlen das
                <a
                    href="https://www.hilton.com/en/hotels/bogatgi-hilton-garden-inn-bogota-airport/?SEO_id=GMB-AMER-GI-BOGATGI&y_source=1_MTMyNjA3NDEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D"
                    target="_blank"
                    rel="noreferrer"
                >
                Hilton Hotel
                </a>
                für Personen, die (vor und/oder nach der Hochzeit) die Gelegenheit nutzen möchten, die Hauptstadt Bogotá zu besichtigen 
                (<i>Anschrift des Hotels: <a
                    href="https://maps.app.goo.gl/NoenV1LWRiiqCq8B8"
                    target="_blank"
                    rel="noreferrer"
                >
                    Cra. 82 #25G 84, Fontibón, Bogotá</a></i>).
                <br />
                Es gibt einen Shuttle Bus Flughafen/Hilton Hotel.
                <br />
                Es gibt die Möglichkeit, mit dem Bus (Abfahrt vor dem Hotel) in die Innenstadt zu gelangen.`,
        textVisit: `<a
                    href="https://www.idt.gov.co/es/puntos-de-informacion-turistica"
                    target="_blank"
                    rel="noreferrer"
                >
                    Einige Ausflüge in Bogotá: 
                </a>
                Candelaria (Stadtzentrum mit Plaza de Bolivar, Plazoleta Chorro de 
                Quevedo und in den Straßen des Stadtzentrums schlendern), 
                Monserrate mit der Seilbahn, 
                das Goldmuseum...`,
        subtitle2: `Anfahrt zur Hochzeit`,
        textVol: `Flug Bogotá – Santa Marta: 1h30 Flug
                <br />
                Denkt daran, früh zu buchen.
                <br />
                Ein Hinflug kostet etwa 25€ (ohne aufgegebenes Gepäck).
                <br />
                Möglichkeit von Direktflügen aus anderen kolumbianischen 
                Städten (z. B. Cali, Medellin) nach Santa Marta möglich.`,
        subtitle3: `Transfer zum Hochzeitsort`,
        textTransfert_title1: `Erste Option`,
        textTransfert_text1: `Vom Flughafen Santa Marta nach Palomino mit dem Taxi (1h30-2h, ca. 40€)`,
        textTransfert_title2: `Zweite Option`,
        textTransfert_text2: `Vom Flughafen Santa Marta zum Mercado Publico in Santa Marta mit dem 
                        Taxi (30 Minuten, ca. 10€), dann vom Mercado Publico in Santa Marta 
                        nach Palomino mit dem Bus: Abfahrt etwa jede Viertelstunde, mit 
                        häufigen Stopps (2h30, ca. 3€ pro Person)`,
        subtitle4: `Einige zusätzliche Tipps`,
        text4: `<li>
                    In Kolumbien wird Spanisch gesprochen. In touristischen Orten 
                    wird auch Englisch benutzt, aber es ist immer gut, ein paar Worte 
                    Spanisch zu sprechen.
                </li>
                <li>
                    In Kolumbien wird vieles verhandelt (Taxi, Straßenmärkte).
                </li>
                <li>
                    Wir würden euch in allen Städten außer Santa Marta (gibt es dort nicht) die App „Uber“
                    empfehlen, weil die Preise vor der Fahrt schon transparent sind.
                </li>
                <li>
                    In Kolumbien herrscht je nach Region ein unterschiedliches Klima: 
                    Bogota 20 Grad, Medellin 25 Grad, Palomino 30 Grad... denkt also daran beim 
                    Packen.
                </li>
                <li>
                    Das Herunterladen der „Offline-Karten“ in Google Maps kann bei der 
                    Orientierung hilfreich sein.
                </li>
                <li>
                    Hebt vor eurer Ankunft in Palomino ausreichend Bargeld ab, da es dort keinen 
                    Geldautomaten gibt (die kolumbianische Währung ist der COP, kolumbianische Peso).
                </li>`,
    },
    participation: {
        title: `RSVP`,
        text: `Bitte bestätigt Eure Anwesenheit, indem Ihr das untenstehende Formular ausfüllt
                <br />
                <span id="participationLimitDate">vor dem 15. November 2024</span>,
                letzte Frist.`,
        contact: `Wenn Sie bei der Anmeldung Probleme haben, senden Sie bitte eine E-Mail an 
                <a href="mailto:contact@leita-jorgito.com">
                    contact@leita-jorgito.com
                </a>
                oder kontaktieren Sie Léa`,
        label1: `Vor-und Nachname`,
        label2: "Anzahl der Gäste",
        label3: `Telefonnummer`,
        label4: `Diätetische Einschränkungen? Allergien? Welche?`,
        label5: `Lied-/Tanzwünsche`,
        label6: `Kommentare`,
        label7: `Senden`,
        label8: `Ja`,
        label9: `Nein`,
        answer: "Antwort gesendet !",
        error: "Entschuldigung, ein Fehler ist aufgetreten.<br/>Bitte versuchen Sie Ihre Anmeldung erneut."
    },
    activities: {
        title: `Aktivitäten`,
        subtitle1: `Aktivitäten rund um das Hotel in Palomino`,
        activity1: "Tubing",
        activity2: "Surfen",
        activity3: "Yoga",
        activity4: "Entspannen",
        subtitle2: `Ausflüge in der Region von Santa Marta`,
        visit1: `<p>Tayrona-Nationalpark<b />
                <i>vom 1. bis 15. Februar geschlossen (Wandern und Strände)</i></p>`,
        visit2: `<p>Minca<br /><i>(Wandern, Wasserfälle und Vogelbeobachtung)</i></p>`,
        visit3: `<p>Historisches Zentrum von Santa Marta</p>`,
        visit4: `<p>Karneval in Barranquilla vom 1. bis 4. März 2025</p>`,
        text: `Wir werden uns am Sonntag, den 16. Februar um 12 Uhr verabschieden und 
                es ist Euch überlassen, euren Aufenthalt vor Ort oder in der Umgebung 
                zu verlängern.`,
        subtitle3: `Hierbei findet ihr einige andere Hotels/Hostels in Palomino`,
        hotel1: `<p><a href="https://www.makaopalomino.com/?lang=en" alt="Makao Beach Hotel website">Makao Beach Hotel</a></p>`,
        hotel2: `<p><a href="https://www.hotelchiniu.com/" alt="Chiniü Hotel website">Hotel Chiniü</a></p>`,
        hotel3: `<p><a href="https://www.aite.com.co/" alt="Aite Eco Resort website">Aite Eco Resort</a></p>`,
        hotel4: `<p><a href="https://www.thedreamer.com/" alt="Dreamer Palomino Hostal website">Dreamer Palomino Hostal</a></p>`,
    },
};

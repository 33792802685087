export const textFR = {
    nav: {
        home: "Accueil",
        ceremonie: "Mariage",
        infos: "Informations pratiques",
        participation: "Participation",
        activities: "Activités possibles",
    },
    home: {
        title: "Notre mariage",
        dateTitle: "Date",
        date: `15 Février 2025`,
        text: `Chère famille, chers amis,
                <br />
                <br />
                Nous sommes très heureux et nous nous réjouissons de
                partager ce jour important de notre mariage avec vous !
                Nous espérons que cette journée sera pour chacun d’entre
                vous également inoubliable.
                <br />
                <br />
                Nous avons pris beaucoup de plaisir à l’organisation de
                notre mariage où deux cultures se réuniront pour célébrer
                l’amour, danser, remercier et partager des moments
                mémorables.
                <br />
                <br />
                Vous trouverez ici quelques informations concernant le lieu,
                l’hôtel, les transports, les activités…
                <br />
                <br />
                N’oubliez pas de confirmer votre présence à l’aide du
                formulaire.
                <br />
                <br />
                D’avance merci et nous espérons vous voir bientôt !`,
    },
    ceremonie: {
        title: "Cérémonie & Festivités",
        textDate: `Date : 15.02.2025 à 16h`,
        textAddress: `Lieu : <a href="https://maps.app.goo.gl/WgdmGNrMh6dJWLfd7" target="_blank">Hotel Coconuco Beach Resort
                    <br />
                    Km 1 via Palomino, Dibulla
                    <br />
                    La Guajira, Colombie</a>`,
        text: `RDV dès le vendredi 14 février 2025 à l’hôtel Coconuco Beach
                Resort où nous aurons le plaisir de vous accueillir pour une
                soirée joyeuse et conviviale.`,
        textLieu: `Lien Google Maps`,
        textHotel: `Nous prendrons en charge les 2 nuits d’hôtel, du 14 au 16
                    février 2025 (en logement et petit-déjeuner).`,
        textDressCode: `Pas de dress code, la cérémonie aura lieu sur la plage, ce sera l’été, donc prévoyez une tenue casual (<i>robes
                        légères, pantalon, chemises en coton / lin</i>).`,
    },
    infos: {
        title: `Informations pratiques pour venir`,
        subtitle1: `Formalités`,
        textForm: `Pour les ressortissants français, un passeport en cours de
                validité est requis.
                <br />
                Il n’y a pas de vaccins obligatoires.
                <br />
                Pour les formalités, nous vous invitons à consulter le
                <a
                    href="https://www.diplomatie.gouv.fr/fr/conseils-aux-voyageurs/conseils-par-pays-destination/colombie/#entree"
                    target="_blank"
                    rel="noreferrer"
                >site du gouvernement</a>.
                <br /><br />
                Le formulaire du
                <a
                    href="https://apps.migracioncolombia.gov.co/pre-registro/en"
                    target="_blank"
                    rel="noreferrer"
                >
                    « Check mig »
                </a>
                sera à remplir en ligne de 72h à 1h avant l’entrée dans le
                pays.`,
        textHotel: `Aéroport d'arrivée en Colombie : Bogotá
                <br />
                Pour les personnes qui feront un stop à Bogota (avant et/ou après le mariage) nous conseillons
                <a
                    href="https://www.hilton.com/en/hotels/bogatgi-hilton-garden-inn-bogota-airport/?SEO_id=GMB-AMER-GI-BOGATGI&y_source=1_MTMyNjA3NDEtNzE1LWxvY2F0aW9uLndlYnNpdGU%3D"
                    target="_blank"
                    rel="noreferrer"
                >
                    l’hôtel Hilton
                </a>(<i>adresse de l'hôtel: <a
                    href="https://maps.app.goo.gl/NoenV1LWRiiqCq8B8"
                    target="_blank"
                    rel="noreferrer"
                >
                    Cra. 82 #25G 84, Fontibón, Bogotá</a></i>).
                <br />
                Possibilité de navette aéroport / hôtel
                <br />
                Possibilité de prendre le bus (départ devant
                l’hôtel) pour se rendre au centre ville.`,
        textVisit: `<a href="https://www.idt.gov.co/es/puntos-de-informacion-turistica"
                    target="_blank"
                    rel="noreferrer"
                >Quelques propositions de visites à Bogota : 
                </a>
                La Candelaria (centre-ville avec la Plaza de Bolivar, la
                Plazoleta Chorro de Quevedo et les ruelles du centre-ville), 
                Monserrate en téléphérique, 
                le musée de l’or…`,
        subtitle2: `Accès pour le mariage`,
        textVol: `Vol Bogota - Santa Marta : 1h30 de vol.
                <br />
                Pensez à réserver tôt.
                <br />
                Pour indication : prix d'un aller simple aux alentours de 25€ (sans bagage en soute).
                <br />
                Possibilités de vols directs au départ d’autres villes
                colombiennes (par exemple Cali, Medellin) vers Santa Marta.`,
        subtitle3: `Transfert vers le lieu du mariage`,
        textTransfert_title1: `Première option`,
        textTransfert_text1: `Depuis l’aéroport de Santa Marta jusqu’à Palomino en taxi
                            (1h30-2h, compter environ 40€)`,
        textTransfert_title2: `Seconde option`,
        textTransfert_text2: `Depuis l’aéroport de Santa Marta jusqu’au mercado publico à
                            Santa Marta en taxi (30min, compter environ 10€), puis depuis le
                            mercado publico de Santa Marta jusqu’à Palomino en bus: départ
                            environ tous les quarts d'heure, arrêts fréquents (compter
                            2h30, environ 3€ par personne)`,
        subtitle4: `Quelques conseils en complément`,
        text4: `<li>
                    En Colombie, la langue parlée est l’espagnol. Dans les
                    lieux touristiques, l’anglais est un peu utilisé, mais il est préférable d’avoir quelques notions d’espagnol.
                </li>
                <li>
                    En Colombie, tout se négocie (taxi, marchés dans les rues).
                </li>
                <li>
                    L’application « Uber » est active dans la plupart des villes de Colombie (sauf à Santa Marta), 
                    n’hésitez pas à l’utiliser pour vos déplacements, les prix sont transparents.
                </li>
                <li>
                    Quelques indications sur le climat en Colombie : Bogotá : aux alentours des 20 degrés toute l’année, 
                    la côte des Caraïbes : 30 à 35 degrés selon la période (saison sèche de décembre à avril) et les villes 
                    côtières pacifiques (par exemple Cali) : 28 à 30 degrés, Medellin : 25 à 30 degrés, pensez-y en préparant vos valises !
                </li>
                <li>
                    Télécharger les "plans hors connexion" dans Google maps peut être
                    pratique pour s’orienter.
                </li>
                <li>
                    Pensez à retirer suffisamment d’argent avant d'arriver à
                    Palomino, car pas d'ATM sur place (la monnaie locale est le COP, peso colombien).
                </li>`,
    },
    participation: {
        title: `Répondez s'il vous plait`,
        text: `Merci de nous confirmer votre présence en remplissant
                le formulaire ci-dessous
                <br />
                <span id="participationLimitDate">avant le 15 novembre 2024</span>,
                dernier délai.`,
        contact: `Si vous rencontrez des problèmes lors de l'inscription, veuillez
                envoyer un mail à 
                <a href="mailto:contact@leita-jorgito.com">
                    contact@leita-jorgito.com
                </a>
                ou contacter Léa`,
        label1: `Prénom et nom`,
        label2: "Nombre de participants",
        label3: `Numéro de téléphone`,
        label4: `Restrictions alimentaires ? Allergies ? Lesquelles ?`,
        label5: `Souhaits de chansons/danses`,
        label6: `Commentaires`,
        label7: `Envoyer`,
        label8: `Oui`,
        label9: `Non`,
        answer: "Réponse envoyée !",
        error: `Désolé, une erreur a été rencontrée.<br/>Veuillez réessayer votre inscription.`
    },
    activities: {
        title: `Activités`,
        subtitle1: `Activités aux alentours de l’hôtel à Palomino`,
        activity1: "Tubing",
        activity2: "Surf",
        activity3: "Yoga",
        activity4: "Relax",
        subtitle2: `Excursions dans la région de Santa Marta`,
        visit1: `<p>Parc national de Tayrona<br /><i>fermé du 1er 
                au 15 février (randonnée et plages)</i></p>`,
        visit2: `<p>Minca<br /><i>(randonnée, cascades et observation d’oiseaux)</i></p>`,
        visit3: `<p>Centre historique de Santa Marta</p>`,
        visit4: `<p>Carnaval de Barranquilla du 1er au 4 mars 2025</p>`,
        text: `Nous nous quitterons le dimanche 16 février à midi après le brunch et libre à
                vous de prolonger votre séjour sur place ou dans les environs.`,
        subtitle3: `Ci-dessous quelques hôtels à Palomino`,
        hotel1: `<p><a href="https://www.makaopalomino.com/?lang=en" alt="Makao Beach Hotel website">Makao Beach Hotel</a></p>`,
        hotel2: `<p><a href="https://www.hotelchiniu.com/" alt="Chiniü Hotel website">Hotel Chiniü</a></p>`,
        hotel3: `<p><a href="https://www.aite.com.co/" alt="Aite Eco Resort website">Aite Eco Resort</a></p>`,
        hotel4: `<p><a href="https://www.thedreamer.com/" alt="Dreamer Palomino Hostal website">Dreamer Palomino Hostal</a></p>`,
    },
};

import "../styles/pages/activities.scss";

import { useSelector } from "react-redux";
import { content } from "../assets/text.js";

import Figure from "../components/Figure";

import TubingImage from "../assets/img/activities/tubing.jpg";
import SurfImage from "../assets/img/activities/surf.jpg";
import YogaImage from "../assets/img/activities/yoga.jpg";
import RelaxImage from "../assets/img/activities/relax.jpg";

import visitTayrona from "../assets/img/activities/visits/parc_de_tayrona.jpg";
import visitMinca from "../assets/img/activities/visits/parc_de_Minca.jpg";
import visitCanaveral from "../assets/img/activities/visits/Carnaval_de_Barranquilla.jpg";
import visitSantaMarta from "../assets/img/activities/visits/santa-marta-activites.jpg";

import hotelMakao from "../assets/img/activities/hotels/Makao_Beach_Hotel.jpg";
import hotelChiniu from "../assets/img/activities/hotels/Hotel_Chiniü.jpg";
import hotelPalomino from "../assets/img/activities/hotels/Dreamer_Palomino_Hostal.jpg";
import hotelAite from "../assets/img/activities/hotels/Aite_Eco_Resort.jpg";

export default function Activities() {
    const lang = useSelector((state) => state.lang);
    const textValues = content[lang];

    return (
        <main id="pageActivities">
            <h1>{textValues.activities.title}</h1>
            <h2>{textValues.activities.subtitle1}</h2>
            <ul>
                <li className="activityElement">
                    <Figure
                        image={TubingImage}
                        alt={textValues.activities.activity1 + "Activity"}
                        text={textValues.activities.activity1}
                        classText="activityFigure"
                    />
                </li>
                <li className="activityElement">
                    <Figure
                        image={SurfImage}
                        alt={textValues.activities.activity2 + "Activity"}
                        text={textValues.activities.activity2}
                        classText="activityFigure"
                    />
                </li>
                <li className="activityElement">
                    <Figure
                        image={YogaImage}
                        alt={textValues.activities.activity3 + "Activity"}
                        text={textValues.activities.activity3}
                        classText="activityFigure"
                    />
                </li>
                <li className="activityElement">
                    <Figure
                        image={RelaxImage}
                        alt={textValues.activities.activity4 + "Activity"}
                        text={textValues.activities.activity4}
                        classText="activityFigure"
                    />
                </li>
            </ul>

            <h2>{textValues.activities.subtitle2}</h2>
            <ul>
                <li className="visitElement">
                    <Figure
                        image={visitTayrona}
                        alt={textValues.activities.visit1 + "Visit"}
                        text={textValues.activities.visit1}
                        classText="visitFigure"
                    />
                </li>
                <li className="visitElement">
                    <Figure
                        image={visitMinca}
                        alt={textValues.activities.visit2 + "Visit"}
                        text={textValues.activities.visit2}
                        classText="visitFigure"
                    />
                </li>
                <li className="visitElement">
                    <Figure
                        image={visitSantaMarta}
                        alt={textValues.activities.visit3 + "Visit"}
                        text={textValues.activities.visit3}
                        classText="visitFigure"
                    />
                </li>
                <li className="visitElement">
                    <Figure
                        image={visitCanaveral}
                        alt={textValues.activities.visit4 + "Visit"}
                        text={textValues.activities.visit4}
                        classText="visitFigure"
                    />
                </li>
            </ul>

            <p
                dangerouslySetInnerHTML={{
                    __html: textValues.activities.text,
                }}
            ></p>

            <h2>{textValues.activities.subtitle3}</h2>
            <ul>
                <li className="hotelElement">
                    <Figure
                        image={hotelMakao}
                        alt={textValues.activities.hotel1 + "Hotel"}
                        text={textValues.activities.hotel1}
                        classText="hotelFigure"
                    />
                </li>
                <li className="hotelElement">
                    <Figure
                        image={hotelChiniu}
                        alt={textValues.activities.hotel2 + "Hotel"}
                        text={textValues.activities.hotel2}
                        classText="hotelFigure"
                    />
                </li>
                <li className="hotelElement">
                    <Figure
                        image={hotelAite}
                        alt={textValues.activities.hotel3 + "Hotel"}
                        text={textValues.activities.hotel3}
                        classText="hotelFigure"
                    />
                </li>
                <li className="hotelElement">
                    <Figure
                        image={hotelPalomino}
                        alt={textValues.activities.hotel4 + "Hotel"}
                        text={textValues.activities.hotel4}
                        classText="hotelFigure"
                    />
                </li>
            </ul>
        </main>
    );
}

import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import icon from "../assets/img/weeding/iconLocation.png";
import L from "leaflet";

export default function MapElement(props) {
    const position = props.position;
    const textPopup = props.popup;

    let PositionIcon = L.icon({
        iconUrl: icon,
        iconSize: [60, 70],
        iconAnchor: [30, 70],
        popupAnchor: [0, -70],
    });

    L.Marker.prototype.options.icon = PositionIcon;

    return (
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={position}>
                <Popup>{textPopup}</Popup>
            </Marker>
        </MapContainer>
    );
}

export default function Figure(props) {
    const imageSrc = props.image;
    const imageAlt = props.alt;
    const text = props.text;
    const classText = props.classText;

    return (
        <figure className={classText}>
            <img src={imageSrc} alt={imageAlt} />
            <figcaption
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            ></figcaption>
        </figure>
    );
}
